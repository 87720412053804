import React, { createContext, useState } from 'react';
import { RequestFormConfigDefault } from "../FormConfig/RequestFormConfigDefault";

// Create the context object with a default value
const RequestContext = createContext();

// Provider component
const RequestProvider = ({ children }) => {
    const [generalData, setGeneralData] = useState({});
    const [onboardingFileData, setOnboardingFileData] = useState({});
    const [onboardingFileUrl, setOnboardingFileUrl] = useState({});
    const [otherDetails, setOtherDetails] = useState({});
    const [supplyDetails, setSupplyDetails] = useState({});
    const [supplyFormLabelData, setSupplyFormLabelData] = useState([]);
    const [commentsData, setCommentsData] = useState([])

    // Function to update the general state data
    const updateGeneralStateData = (data = {}) => {
        setGeneralData(data);
    };

    // Function to update the onboarding file data
    const updateOnboardingFileData = (data = {}) => {
        setOnboardingFileData(data);
    };

    // Function to update the onboarding file URL
    const updateOnboardingFileUrl = (data = {}) => {
        setOnboardingFileUrl(data);
    };

    // Function to update the other details
    const updateOtherDetails = (data = {}) => {
        setOtherDetails(data);
    };

    // Function to update the supply details
    const updateSupplyDetails = (data = {}) => {
        setSupplyDetails(data);
    };

    const updateSupplyLabelFormData = (data = []) => {
        setSupplyFormLabelData(data)
    }

    const updateFullData = (generalData = {}, onboardingFileUrl = {}, otherDetails = {}, supplyDetails = {}, supplyFormLabelData = [], commentsData = []) => {
        setGeneralData(generalData)
        setOnboardingFileUrl(onboardingFileUrl)
        setOtherDetails(otherDetails)
        setSupplyDetails(supplyDetails)
        setCommentsData(commentsData)
    }

    const resetState = () => {
        setGeneralData({})
        setOnboardingFileData({})
        setOnboardingFileUrl({})
        setOtherDetails({})
        setSupplyDetails({})
        setSupplyFormLabelData([])
        setCommentsData([])
    }


    // Value to be passed to the context
    const value = {
        generalData,
        onboardingFileData,
        onboardingFileUrl,
        otherDetails,
        supplyDetails,
        supplyFormLabelData,
        commentsData,
        updateGeneralStateData,
        updateOnboardingFileData,
        updateOnboardingFileUrl,
        updateOtherDetails,
        updateSupplyDetails,
        updateSupplyLabelFormData,
        updateFullData,
        resetState
    };

    return (
        <RequestContext.Provider value={value}>
            {children}
        </RequestContext.Provider>
    );
};

export { RequestContext, RequestProvider };
