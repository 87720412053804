export const NonTSRequestGeneralDetailsFormConfig = (supplyPartnerOptions = [], amgId = "") => {
    return  [
        {
            label: "Channel name",
            value: "",
            type: "text_input",
            key: "channel_name",
            width: "442px",
            placeholder: "Channel name",
            necessary: true
        },
        {
            label: "Customer name",
            value: "",
            type: "text_input",
            key: "customer_name",
            width: "442px",
            placeholder: "Customer name",
            necessary: true
        },
        {
            label: "Supply partner",
            value: "",
            type: "select",
            key: "ads_plus_supply_partner_id",
            width: "442px",
            options: supplyPartnerOptions,
            necessary: true
        },
        {
            label: "Contact",
            type:"inner_contact_table",
            key:"contacts",
        },
        {
            label: "AMG ID",
            value: "AMG001",
            type: "text_input",
            width: "155px",
            key: "amg_id",
            disabled: true
        },
        {
            label: "Pricing information",
            type: "heading",
        },
        {
            label: "Connection type",
            value: "",
            type: "select",
            placeholder: "Connection type",
            options: [
                { value: "vast", label: "Vast" },
                { value: "springserve_dc", label: "Springserve DC" }
            ],
            key: "connection_type",
            necessary: true
        },
        {
            label: "Deal type",
            value: "",
            type: "select",
            placeholder: "Deal type",
            options: [
                { value: "inventory", label: "Inventory" },
                { value: "revenue", label: "Revenue - Exclusive" },
                { value: "fallback", label: "Revenue - Fallback" },
            ],
            key: "deal_type",
            necessary: true
        },
        {
            label: "Commission %",
            value: "",
            type: "input",
            width: "41px",
            post_unit:"%",
            necessary: true,
            key: "commission",
            placeholder: " "
        },
        {
            label: "Ads+ Prioritization",
            value: "",
            type: "select",
            placeholder: "Ads+ Prioritization",
            options: [
                { value: "fixed_cpm", label: "Fixed CPM" },
                { value: "hard_floor", label: "RevShare - Hard Floor" },
                { value: "soft_floor", label: "RevShare - Soft Floor" }
            ],
            key: "ads_plus_prioritization",
        },
        {
            label: "Net floor expectation",
            value: "",
            type: "select",
            key:"net_floor_expectations",
            options: [
                { value: "soft", label: "Soft" },
                { value: "hard", label: "Hard" },
                {value: "fixed", label:"Fixed"}
            ],
        },
        {
            label: "Floor rate",
            value: "",
            type: "input",
            preUnit : "$",
            key:"floor_rate",
            width:"100px",
            placeholder: "Floor rate"
        },
        {
            label: "SO document",
            value: "",
            type: "file_input",
            config: {
                fileType: "Only pdf",
                supportedType: ".pdf",
                subMessage: "Upload your SO document.",
                headMessage: "",
            },
            key: "so_document_file_url",
            download: false,
            necessary: true
        },
        {
            label: "Additional info (Optional)",
            value: "",
            type: "text_input",
            width:"442px",
            map_key: "config",
            placeholder: "Additional info",
            key:"additional_info_txt",
            textLimit: 200
        },
    ]
};
