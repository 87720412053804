import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import PropTypes from "prop-types";
import "./index.css"
import {useEffect, useState} from "react";
import {
    ALL_PARTNER_KEY_DENOTES,
    CURRENCY_DENOTE_TYPE,
    NUMBER_DENOTE_TYPE, OPEN_MARKET_SUMMERY,
    PERCENTAGE_DENOTE_TYPE
} from "../../Constant";
import {formatNumber, removeFractions} from "../../utils/helper";

function DetailsStickyTable(props) {
    const [tableData, setTableData] = useState([]);
    const [tableKey, setTableKey] = useState([]);

    useEffect(() => {
        let columnData = []
        if(props.filterPartnerName !== "" && props.tableData?.length > 0 && props.tableKey?.length > 0 && props.type !== OPEN_MARKET_SUMMERY){
            props.tableData.map((data) => {
                data?.partner_details?.map((partnerData) => {
                    if((partnerData?.supply_partner_name || partnerData?.demand_partner_name) === props.filterPartnerName){
                        columnData.push({...partnerData})
                    }
                    return true
                })
                return true
            })
        }else if(props.filterPartnerName !== "" && props.tableData?.length > 0 && props.tableKey?.length > 0 && props.type === OPEN_MARKET_SUMMERY){
            props.tableData.map((data) => {
                data?.open_market_summery?.map((partnerData) => {
                    if(partnerData?.bidder_account_name === props.filterPartnerName){
                        columnData.push({...partnerData})
                    }
                    return true
                })
                return true
            })
        }
        setTableData(columnData)
        setTableKey(props.tableKey)

    }, [props?.filterPartnerName, props?.tableData, props?.tableKey, props.type])

    return(
        <Grid item xs={12} className="ads-plus-table-container detail-table">
            <Table className="ads-plus-reporting-table" stickyHeader>
                <TableHead>
                    <TableCell component="th" scope="row" size="medium" style={{
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        left: 0,
                        zIndex: 9
                    }} className="right-border">
                        Date
                    </TableCell>
                    {
                        props.tableColumns.map((header) => {
                            return(
                                <TableCell key={header} component="th" scope="row" size="medium">
                                    {header}
                                </TableCell>
                            )
                        })
                    }
                </TableHead>
                <TableBody>
                    {
                        tableData.map((data) => {
                            return(
                                <TableRow key={data.date}>
                                    <TableCell style={{
                                        whiteSpace: 'nowrap',
                                        position: 'sticky',
                                        left: 0
                                    }} size="medium" className="right-border" > {data?.["date"]} </TableCell>
                                    {tableKey?.length > 0 && tableKey.map((key) => {
                                        if(ALL_PARTNER_KEY_DENOTES[key] === CURRENCY_DENOTE_TYPE){
                                            return(
                                                <TableCell size="medium"> ${removeFractions(data?.[key]) || 0 }</TableCell>
                                            )
                                        }

                                        if(ALL_PARTNER_KEY_DENOTES[key] === NUMBER_DENOTE_TYPE){
                                            return(
                                                <TableCell size="medium"> {formatNumber(removeFractions(data?.[key])) || 0 } </TableCell>
                                            )
                                        }

                                        if(ALL_PARTNER_KEY_DENOTES[key] === PERCENTAGE_DENOTE_TYPE){
                                            return(
                                                <TableCell size="medium"> {data?.[key] ? `${removeFractions(data[key])}%` : 0 } </TableCell>
                                            )
                                        }

                                    })}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
    )
}

DetailsStickyTable.propTypes = {
    "tableColumns": PropTypes.arrayOf(PropTypes.string.isRequired),
    "tableData": PropTypes.arrayOf(PropTypes.shape({
        "date": PropTypes.string.isRequired,
        "partner_details": PropTypes.arrayOf(
            PropTypes.shape({
                "date": PropTypes.string.isRequired,
                "supply_partner_id": PropTypes.number.isRequired,
                "supply_partner_name": PropTypes.string.isRequired,
                "revenue": PropTypes.number.isRequired,
                "cost": PropTypes.number.isRequired,
                "impressions": PropTypes.number.isRequired,
                "net_revenue": PropTypes.number.isRequired,
                "margin_per": PropTypes.number.isRequired,
                "bids": PropTypes.number.isRequired,
                "wins": PropTypes.number.isRequired,
                "fill_rate": PropTypes.number.isRequired,
                "supply_timeouts": PropTypes.number.isRequired,
                "e_cpm": PropTypes.number.isRequired,
                "rpm": PropTypes.number.isRequired,
                "ad_request": PropTypes.number.isRequired,
                "ad_return": PropTypes.number.isRequired,
                "ad_filled": PropTypes.number.isRequired,
                "ad_fill_rate": PropTypes.number.isRequired
            })
        ),
        "open_market_summery": PropTypes.arrayOf(PropTypes.shape({
            "bid_rate": PropTypes.number.isRequired,
            "bidder_account_id": PropTypes.number.isRequired,
            "bidder_account_name": PropTypes.string.isRequired,
            "bidder_errors": PropTypes.number.isRequired,
            "bids": PropTypes.number.isRequired,
            "cost": PropTypes.number.isRequired,
            'date': PropTypes.string.isRequired,
            "demand_partner_id": PropTypes.number.isRequired,
            "erpm": PropTypes.number.isRequired,
            "impressions": PropTypes.number.isRequired,
            "margin": PropTypes.number.isRequired,
            "margin_per": PropTypes.number.isRequired,
            "requests": PropTypes.number.isRequired,
            "response_time": PropTypes.number.isRequired,
            "revenue": PropTypes.number.isRequired,
            "use_rate": PropTypes.number.isRequired,
            "win_rate": PropTypes.number.isRequired,
            "wins": PropTypes.number.isRequired
        }))
    })),
    "allDayPartnerTotalRevenue": PropTypes.number.isRequired,
    "filterPartnerName": PropTypes.string.isRequired,
    "tableKey": PropTypes.arrayOf(PropTypes.string.isRequired),
    "type": PropTypes.string.isRequired,
}

export default DetailsStickyTable