import {SIMPLE_SELECT_TYPE, TEXT_INPUT_TYPE, TYPE_INPUT, TYPE_RADIO, TYPE_VECTOR, WIDTH_300_PX} from "../Constant";
import {getLanguage} from "../__mock__/getLanguage";


export const TSOtherDetailsFormConfig = [
    {
        label:"Supply Description",
        type:"heading",
        sectionId:"supply_description",
        key: "supply_details"
    },
    {
        label: "Content language",
        value: "",
        type: SIMPLE_SELECT_TYPE,
        width: WIDTH_300_PX,
        necessary:true,
        placeholder: "Language",
        options: getLanguage,
        key:"content_language"
    },
    {
        label: "Content description",
        key:"content_descriptions",
        type: TEXT_INPUT_TYPE,
        value: "",
        placeholder:"Description",
        width:WIDTH_300_PX,
        textLimit: 200
    },
    {
        label: "Average pod duration",
        key:"avg_pod_durations_in_sec",
        type: TYPE_INPUT,
        post_unit:"secs",
        width: "44px",
        placeholder: " "
    },
    {
        label:"Device distribution",
        type:"inner_html_device_table",
        necessary: true,
        key: "device_type"
    },
    {
        label:"Geo distribution",
        type:"inner_html_geo_table",
        necessary: true,
        key: "inventory_available_in_geo"
    },
    {
        label: "Ad placement type",
        key:"ad_placement_type",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "In-Stream - Pre-Roll", value: "pre_roll"},
            {label: "In-Stream - Mid-Roll", value: "mid_roll"},
            {label: "In-Stream - Post-Roll", value: "post_roll"},
            {label: "Outstream", value: "outstream"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Placement type"
    },
    {
        label: "Is supply LDA-Compliant?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"is_supply_lda_content_compliant"
    },
    {
        label: "Is supply COPPA inventory?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"is_supply_coppa_inventory_enable"
    },
    {
        label: "Ads.txt URL",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"ads_txt_content_url",
        width:WIDTH_300_PX,
        placeholder: " ",
        necessary: true
    },
    {
        label: "App-ads.txt URL",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"app_ads_txt_content_url",
        width: WIDTH_300_PX,
        placeholder: " ",
        necessary: true
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"Demand Information",
        type:"heading",
        sectionId:"demand_information",
        key: "demand_details"
    },
    {
        label: "Rights for Amagi to use customer logo and/or marketing material?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"right_for_amagi_to_marketing"
    },
    // {
    //     label: "Direct Sales team availability",
    //     value: "",
    //     type: TYPE_RADIO,
    //     options: [
    //         { value: "true", label: "Yes" },
    //         { value: "false", label: "No" },
    //     ],
    //     key:"direct_sales_team_availability"
    // },
    {
        label: "Ad Quality Requirements",
        key:"ad_quality_requirements",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "Basic Sensitive Category Blocking", value: "basic_sensitivity_category_blocking"},
            {label: "AD domain Blocklist", value: "ad_domain_blocklist"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Select"
    },
    {
        label: "If ad domain blocklist, provide link",
        key:"adomain_blocklist_link",
        dependant_key: "ad_quality_requirements",
        type:TEXT_INPUT_TYPE,
        width: WIDTH_300_PX,
    },
    {
        label: "Political policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"political_policy_allowed"
    },
    {
        label: "Pharma policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"pharma_policy_allowed"
    },
    {
        label: "Gambling policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"gambling_policy_allowed"
    },
    {
        label: "Alcohol policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"alcohol_policy_allowed"
    },
    {
        label: "Cannabis policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"cannabis_policy_allowed"
    },
    {
        label: "Duplicate Creatives Allowed",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Allowed" },
            { value: "false", label: "Blocked" },
        ],
        key:"duplicate_creative_allowed"
    },
    {
        label: "Duplicate advertiser domain allowed",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Allowed" },
            { value: "false", label: "Blocked" },
        ],
        key:"duplicate_advertiser_domain_allowed"
    },
    {
        label: "Link to sales collateral",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"link_to_sale_collateral",
        width: WIDTH_300_PX,
        placeholder: " ",
    },
    {
        label: "Ad category / Advertiser restrictions",
        key:"ad_category_restrictions",
        type:TEXT_INPUT_TYPE,
        width: WIDTH_300_PX,
        placeholder: " "
    },
    {
        type:TYPE_VECTOR
    },
    {
        label:"Technical Details",
        type:"heading",
        sectionId:"technical_details",
        key: "technical_details"
    },
    {
        label: "Position of Amagi in current waterfall",
        key:"positions_in_amagi_current_waterfall",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "Exclusive OR First Look", value: "exclusive_or_first_look"},
            {label: "Backfill", value: "backfill"},
            {label: "Price Compete", value: "price_compete"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Select",
        leftMargin: "8px"
    },
    {
        label: "Ad trafficking choice",
        key:"traffic",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "Provide tags for my ad server", value: "provide_tags_for_ad_server"},
            {label: "Please traffic directly on Amagi end", value: "traffic_on_amagi_end"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Select",
        leftMargin: "8px"
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"Partnership expectations",
        type:"heading",
        sectionId:"partnership_expectations",
        key: "partner_expectations"
    },
    {
        label: "Monthly revenue",
        value: "",
        type: TYPE_INPUT,
        key:"monthly_revenue",
        width: "285px",
        placeholder: " ",
        preUnit : "$"

    },
    {
        label: "Net eCPM",
        value: "",
        type: TYPE_INPUT,
        key:"net_ecpm",
        width: "48px",
        placeholder: " ",
        preUnit : "$"
    },
    {
        label: "Fill rate",
        value: "",
        type: TYPE_INPUT,
        key:"fill_rate",
        width: "60px",
        placeholder: " ",
        post_unit: "%"
    },
    {
        label: "Any other KPI's?",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"any_other_kpis",
        width: WIDTH_300_PX,
        placeholder: " ",
        textLimit: 200
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"App supply details",
        type:"heading",
        sectionId:"app_supply_details",
        key: "app_supply_details"
    }
]