import {SIMPLE_SELECT_TYPE, TEXT_INPUT_TYPE, TYPE_INPUT, TYPE_RADIO, TYPE_VECTOR, WIDTH_300_PX} from "../Constant";
import {getLanguage} from "../__mock__/getLanguage";
const languages_ordered = getLanguage.sort((left, right) => left.label.localeCompare(right.label));

export const NonTSotherDetailsFormConfig = [
    {
        label:"Supply Description",
        type:"heading",
        sectionId:"supply_description",
        key: "supply_details"
    },
    {
        label: "Content language",
        value: "",
        type: SIMPLE_SELECT_TYPE,
        width: WIDTH_300_PX,
        necessary:true,
        placeholder: "Language",
        options: languages_ordered,
        key:"content_language"
    },
    {
        label: "Content description",
        key:"content_descriptions",
        type: TEXT_INPUT_TYPE,
        value: "",
        placeholder:"Description",
        width:WIDTH_300_PX,
        textLimit: 200
    },
    {
        label: "Average pod duration",
        key:"avg_pod_durations_in_sec",
        type: TYPE_INPUT,
        post_unit:"secs",
        width: "44px",
        placeholder: " "
    },
    {
        label:"Device distribution",
        type:"inner_html_device_table",
        necessary: true,
        key: "device_type"
    },
    {
        label:"Geo distribution",
        type:"inner_html_geo_table",
        necessary: true,
        key: "inventory_available_in_geo"
    },
    {
        label: "Ad placement type",
        key:"ad_placement_type",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "In-Stream - Pre-Roll", value: "pre_roll"},
            {label: "In-Stream - Mid-Roll", value: "mid_roll"},
            {label: "In-Stream - Post-Roll", value: "post_roll"},
            {label: "Outstream", value: "outstream"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Placement type"
    },
    {
        label: "Is supply LDA-compliant?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"is_supply_lda_content_compliant"
    },
    {
        label: "Is supply COPPA inventory?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"is_supply_coppa_inventory_enable"
    },
    {
        label: "Ads.txt URL",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"ads_txt_content_url",
        width:WIDTH_300_PX,
        placeholder: " ",
        necessary: true
    },
    {
        label: "App-ads.txt URL",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"app_ads_txt_content_url",
        width: WIDTH_300_PX,
        placeholder: " ",
        necessary: true
    },
    {
        label: "Seller.json URL",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"seller_json_url",
        width: WIDTH_300_PX,
        placeholder: " "
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"Demand Information",
        type:"heading",
        sectionId:"demand_information",
        key: "demand_details"
    },
    {
        label: "Rights for Amagi to use customer logo and/or marketing material?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"right_for_amagi_to_marketing"
    },
    // {
    //     label: "Direct Sales team availability",
    //     value: "",
    //     type: TYPE_RADIO,
    //     options: [
    //         { value: "true", label: "Yes" },
    //         { value: "false", label: "No" },
    //     ],
    //     key:"direct_sales_team_availability"
    // },
    {
        label: "Ad quality requirements",
        key:"ad_quality_requirements",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "Basic sensitive category blocking", value: "basic_sensitivity_category_blocking"},
            {label: "Ad domain blocklist", value: "ad_domain_blocklist"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Select"
    },
    {
        label: "Political policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"political_policy_allowed"
    },
    {
        label: "Pharma policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"pharma_policy_allowed"
    },
    {
        label: "Gambling policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"gambling_policy_allowed"
    },
    {
        label: "Alcohol policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"alcohol_policy_allowed"
    },
    {
        label: "Cannabis policy",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Always Allowed" },
            { value: "false", label: "Never Allowed" },
        ],
        key:"cannabis_policy_allowed"
    },
    {
        label: "Duplicate creatives allowed",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Allowed" },
            { value: "false", label: "Blocked" },
        ],
        key:"duplicate_creative_allowed"
    },
    {
        label: "Duplicate advertiser domain allowed",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Allowed" },
            { value: "false", label: "Blocked" },
        ],
        key:"duplicate_advertiser_domain_allowed"
    },
    {
        label: "Sales collateral",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"link_to_sale_collateral",
        width: WIDTH_300_PX,
        placeholder: " ",
    },
    // {
    //     label: "Ad Category/ Advertiser restrictions",
    //     key:"ad_category_restrictions",
    //     type:SIMPLE_SELECT_TYPE,
    //     options: [
    //         {label: "Top", value: "top"},
    //         {label: "Bottom", value: "bottom"}
    //     ],
    //     width: WIDTH_300_PX,
    //     placeholder: "Select"
    // },
    // {
    //     label: "Advertiser domain blocklist",
    //     key:"ad_domain_block_list",
    //     type:SIMPLE_SELECT_TYPE,
    //     options: [
    //         {label: "Top", value: "top"},
    //         {label: "Bottom", value: "bottom"}
    //     ],
    //     width: WIDTH_300_PX,
    //     placeholder: "Select"
    // },
    {
        type:TYPE_VECTOR
    },
    {
        label:"Technical Details",
        type:"heading",
        sectionId:"technical_details",
        key: "technical_details"
    },
    {
        label: "Ad server",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"ad_server_url",
        width: WIDTH_300_PX,
        placeholder: " "
    },
    {
        label: "Pre-fetching of ads?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"pre_fetching_ads"
    },
    {
        label: "How far in advance?",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"pre_fetch_advance_time",
        dependant_key :"pre_fetching_ads",
        width: WIDTH_300_PX,
        placeholder: " "
    },
    {
        label: "Ad server timeout max value?",
        key:"ad_server_max_timeout",
        type: TYPE_INPUT,
        post_unit:"ms",
        width: "100px",
        placeholder: " "
    },
    {
        label: "Position of Amagi in current waterfall?",
        key:"positions_in_amagi_current_waterfall",
        type:SIMPLE_SELECT_TYPE,
        options: [
            {label: "Exclusive OR First Look", value: "exclusive_or_first_look"},
            {label: "Backfill", value: "backfill"},
            {label: "Price Compete", value: "price_compete"}
        ],
        width: WIDTH_300_PX,
        placeholder: "Select",
        leftMargin: "8px"
    },
    {
        label: "Ad stitching/SSAI?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"ad_stitching_enable"
    },
    {
        label: "SSAI vendor?",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"stitching_vendor_name",
        dependant_key :"ad_stitching_enable",
        width: WIDTH_300_PX,
        placeholder: " "
    },
    // {
    //     label: "SSAI Vendor",
    //     value: "",
    //     type: TEXT_INPUT_TYPE,
    //     key:"ssai_vendor",
    //     width: WIDTH_300_PX,
    //     placeholder: " ",
    // },
    {
        label: "Ad podding?",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Dynamic" },
            { value: "false", label: "None" },
        ],
        key:"ad_poding_enable"
    },
    {
        label: "Multiple ad response",
        value: "",
        type: TYPE_RADIO,
        options: [
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
        ],
        key:"multiple_ad_response_enable"
    },
    {
        label: "Supported VAST version",
        key:"supported_vast_versions",
        type:SIMPLE_SELECT_TYPE,
        options : [
            { value: "vast_2.0", label: "VAST 2.0" },
            { value: "vast_3.0", label: "VAST 3.0" },
            { value: "vast_4.0", label: "VAST 4.0" },
        ],
        width: WIDTH_300_PX,
        placeholder: "Select"
    },
    {
        label: "Max. creative length",
        key:"max_creative_length",
        type: TYPE_INPUT,
        post_unit:"secs",
        width: "100px",
        placeholder: " ",
        necessary: true
    },
    {
        label: "Max. acceptable bit rate",
        key:"max_acceptable_bit_rate",
        type: TYPE_INPUT,
        post_unit:"Kbps",
        width: "100px",
        placeholder: " "
    },
    {
        label: "Min. acceptable bit rate",
        key:"min_acceptable_bit_rate",
        type: TYPE_INPUT,
        post_unit:"Kbps",
        width: "100px",
        placeholder: " "
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"Partnership expectations",
        type:"heading",
        sectionId:"partnership_expectations",
        key: "partner_expectations"
    },
    {
        label: "Monthly revenue",
        value: "",
        type: TYPE_INPUT,
        key:"monthly_revenue",
        width: "285px",
        placeholder: " ",
        preUnit : "$"

    },
    {
        label: "Net eCPM",
        value: "",
        type: TYPE_INPUT,
        key:"net_ecpm",
        width: "48px",
        placeholder: " ",
        preUnit : "$"
    },
    {
        label: "Fill rate",
        value: "",
        type: TYPE_INPUT,
        key:"fill_rate",
        width: "60px",
        placeholder: " ",
        post_unit: "%",
        textLimit: 200
    },
    {
        label: "Any other KPI's?",
        value: "",
        type: TEXT_INPUT_TYPE,
        key:"any_other_kpis",
        width: WIDTH_300_PX,
        placeholder: " ",
        textLimit: 200
    },
    {
        type: TYPE_VECTOR
    },
    {
        label:"App supply details",
        type:"heading",
        sectionId:"app_supply_details",
        key: "app_supply_details"
    }
]