export const requestDemandInformationConfig = [
    {
        label: "Rights for Amagi to use customer logo and/or marketing material",
        type: "text",
        map_key: "demand_details",
        key: "right_for_amagi_to_marketing",
        bool_type: "yes_no"
    },
    {
        label: "Direct sales team availability",
        type: "text",
        map_key: "demand_details",
        key: "direct_sales_team_availability",
        bool_type: "yes_no"
    },
    {
        label: "Ad quality requirements",
        type: "text_with_info",
        map_key: "demand_details",
        key: "ad_quality_requirements",
    },
    {
        label: "Political policy",
        type: "text",
        map_key: "demand_details",
        key: "political_policy_allowed",
    },
    {
        label: "Pharma policy",
        type: "text",
        map_key: "demand_details",
        key: "pharma_policy_allowed",
    },
    {
        label: "Gambling policy",
        type: "text",
        map_key: "demand_details",
        key: "gambling_policy_allowed",
    },
    {
        label: "Alcohol policy",
        type: "text",
        map_key: "demand_details",
        key: "alcohol_policy_allowed",
    },
    {
        label: "Cannabis policy",
        type: "text",
        map_key: "demand_details",
        key: "cannabis_policy_allowed",
    },
    {
        label: "Duplicate creatives",
        type: "text",
        map_key: "demand_details",
        key: "duplicate_creative_allowed",
    },
    {
        label: "Duplicate advertiser domain",
        type: "text",
        map_key: "demand_details",
        key: "duplicate_advertiser_domain_allowed",
    },
    {
        label: "Link to sales collateral",
        type: "doc_link",
        map_key: "demand_details",
        key: "link_to_sale_collateral",
    },
    {
        label: "Ad Category/ Advertiser restrictions",
        type: "text",
        map_key: "demand_details",
        key:"ad_category_restrictions"
    }
];

export const requestPartnershipExpectationsConfig = [
    {
        label: "Monthly revenue",
        type: "text",
        map_key: "partner_expectations",
        key: "monthly_revenue",
        prefix: "$"
    },
    {
        label: "Net eCPM",
        type: "text",
        map_key: "partner_expectations",
        key: "net_ecpm",
        prefix: "$"
    },
    {
        label: "Fill rate",
        type: "text",
        map_key: "partner_expectations",
        key: "fill_rate",
        suffix: "%"
    },
    {
        label: "Any other KPIs",
        type: "text",
        map_key: "partner_expectations",
        key: "any_other_kpis",
    }
];

export const requestSupplyDescriptionConfig = [
    {
        label: "Content language",
        type: "text",
        map_key: "supply_details",
        key: "content_language",
    },
    {
        label: "Content description",
        type: "text",
        map_key: "supply_details",
        key: "content_descriptions",
    },
    {
        label: "Average pod duration",
        type: "text",
        map_key: "supply_details",
        key: "avg_pod_durations_in_sec",
    },
    {
        label: "Device distribution",
        type: "table_device_distribution",
        map_key: "supply_details",
        key: "device_type",
    },
    {
        label: "Geo distribution",
        type: "table_geo_distribution",
        map_key: "supply_details",
        key: "inventory_available_in_geo",
    },
    {
        label: "Ad placement type",
        type: "text",
        map_key: "supply_details",
        key: "ad_placement_type",
    },
    {
        label: "Is supply LDA compliant",
        type: "text",
        map_key: "supply_details",
        key: "is_supply_lda_content_compliant",
        bool_type: "yes_no"
    },
    {
        label: "Is supply COPPA inventory",
        type: "text",
        map_key: "supply_details",
        key: "is_supply_coppa_inventory_enable",
        bool_type: "yes_no"
    },
    {
        label: "Ads.txt",
        type: "doc_link",
        map_key: "supply_details",
        key: "ads_txt_content_url",
    },
    {
        label: "App-ads.txt",
        type: "doc_link",
        map_key: "supply_details",
        key: "app_ads_txt_content_url",
    }
];

export const requestTechnicalDetailsConfig = [
    {
        label: "Where will Ads Plus sit in your waterfall?",
        type: "text",
        map_key: "technical_details",
        key: "positions_in_amagi_current_waterfall_text",
    },
    {
        label: "Ad trafficking choice",
        type: "text",
        map_key: "technical_details",
        key: "traffic",
    }
];


export const requestDemandInformationConfigNonTS = [
    {
        label: "Rights for Amagi to use customer logo and/or marketing material",
        type: "text",
        map_key: "demand_details",
        key: "right_for_amagi_to_marketing",
        bool_type: "yes_no"
    },
    {
        label: "Direct sales team availability",
        type: "text",
        map_key: "demand_details",
        key: "direct_sales_team_availability",
        bool_type: "yes_no"
    },
    {
        label: "Ad quality requirements",
        type: "text_with_info",
        map_key: "demand_details",
        key: "ad_quality_requirements",
    },
    {
        label: "Political policy",
        type: "text",
        map_key: "demand_details",
        key: "political_policy_allowed",
    },
    {
        label: "Pharma policy",
        type: "text",
        map_key: "demand_details",
        key: "pharma_policy_allowed",
    },
    {
        label: "Gambling policy",
        type: "text",
        map_key: "demand_details",
        key: "gambling_policy_allowed",
    },
    {
        label: "Alcohol policy",
        type: "text",
        map_key: "demand_details",
        key: "alcohol_policy_allowed",
    },
    {
        label: "Cannabis policy",
        type: "text",
        map_key: "demand_details",
        key: "cannabis_policy_allowed",
    },
    {
        label: "Duplicate creatives",
        type: "text",
        map_key: "demand_details",
        key: "duplicate_creative_allowed",
    },
    {
        label: "Duplicate advertiser domain",
        type: "text",
        map_key: "demand_details",
        key: "duplicate_advertiser_domain_allowed",
    },
    {
        label: "Link to sales collateral",
        type: "doc_link",
        map_key: "demand_details",
        key: "link_to_sale_collateral",
    }
];

export const requestPartnershipExpectationsConfigNonTS = [
    {
        label: "Monthly revenue",
        type: "text",
        map_key: "partner_expectations",
        key: "monthly_revenue",
        prefix: "$"
    },
    {
        label: "Net eCPM",
        type: "text",
        map_key: "partner_expectations",
        key: "net_ecpm",
        prefix: "$"
    },
    {
        label: "Fill rate",
        type: "text",
        map_key: "partner_expectations",
        key: "fill_rate",
        suffix: "%"
    },
    {
        label: "Any other KPIs",
        type: "text",
        map_key: "partner_expectations",
        key: "any_other_kpis",
    }
];

export const requestSupplyDescriptionConfigNonTS = [
    {
        label: "Content language",
        type: "text",
        map_key: "supply_details",
        key: "content_language",
    },
    {
        label: "Content description",
        type: "text",
        map_key: "supply_details",
        key: "content_descriptions",
    },
    {
        label: "Average pod duration",
        type: "text",
        map_key: "supply_details",
        key: "avg_pod_durations_in_sec",
        suffix: "secs"
    },
    {
        label: "Device distribution",
        type: "table_device_distribution",
        map_key: "supply_details",
        key: "device_type",
    },
    {
        label: "Geo distribution",
        type: "table_geo_distribution",
        map_key: "supply_details",
        key: "inventory_available_in_geo",
    },
    {
        label: "Ad placement type",
        type: "text",
        map_key: "supply_details",
        key: "ad_placement_type",
    },
    {
        label: "Is supply LDA compliant",
        type: "text",
        map_key: "supply_details",
        key: "is_supply_lda_content_compliant",
        bool_type: "yes_no"
    },
    {
        label: "Is supply COPPA inventory",
        type: "text",
        map_key: "supply_details",
        key: "is_supply_coppa_inventory_enable",
        bool_type: "yes_no"
    },
    {
        label: "Ads.txt",
        type: "doc_link",
        map_key: "supply_details",
        key: "ads_txt_content_url",
    },
    {
        label: "App-ads.txt",
        type: "doc_link",
        map_key: "supply_details",
        key: "app_ads_txt_content_url",
    },
    {
        label: "Seller.json URL",
        type: "doc_link",
        map_key: "supply_details",
        key: "seller_json_url",
    }
];

export const requestTechnicalDetailsConfigNonTS = [
    {
        label: "Pre-fetching of ads",
        type: "text",
        map_key: "technical_details",
        key: "pre_fetching_ads",
        bool_type: "yes_no"
    },
    {
        label: "Ad server timeout max value",
        type: "text",
        map_key: "technical_details",
        key: "ad_server_max_timeout",
        suffix: "ms"
    },
    {
        label: "Position of Amagi in current waterfall",
        type: "text",
        map_key: "technical_details",
        key: "positions_in_amagi_current_waterfall_text",
    },
    {
        label: "Ad stitching/SSAI",
        type: "text",
        map_key: "technical_details",
        key: "ad_stitching_enable",
        bool_type: "yes_no"
    },
    {
        label: "SSAI vendor",
        type: "text",
        map_key: "technical_details",
        key: "stitching_vendor_name",
    },
    {
        label: "Ad podding",
        type: "text",
        map_key: "technical_details",
        key: "ad_poding_enable",
    },
    {
        label: "Multiple ad response",
        type: "text",
        map_key: "technical_details",
        key: "multiple_ad_response_enable",
        bool_type: "yes_no"
    },
    {
        label: "Supported VAST version",
        type: "text",
        map_key: "technical_details",
        key: "supported_vast_versions",
    },
    {
        label: "Max. creative length",
        type: "text",
        map_key: "technical_details",
        key: "max_creative_length",
        suffix: "ms"
    },
    {
        label: "Min. acceptable bitrate",
        type: "text",
        map_key: "technical_details",
        key: "min_acceptable_bit_rate",
        suffix: "kbps"
    },
    {
        label: "Max. acceptable bitrate",
        type: "text",
        map_key: "technical_details",
        key: "max_acceptable_bit_rate",
        suffix: "kbps"
    }
];